import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "DMI Process",
      description: {
        internal: {
          content:
            "The Blink DMI™ process delivers multistorey buildings as a kit of configurable parts that are manufactured offsite and integrated very quickly and safely onsite.",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextAndChecklistSection",
        heading: "Blink DMI® Process",
        upperContent: {
          raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"The Blink DMI","nodeType":"text"},{"data":{},"marks":[],"value":"®","nodeType":"text"},{"data":{},"marks":[],"value":" process delivers multistorey buildings as a kit of configurable parts that are manufactured offsite and integrated very quickly and safely onsite.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"By designing, manufacturing and integrating, with PT Blink you maximise speed and reduce costs, while maintaining the ability to configure the final product for a wide range of design expressions.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
        },
        checklistItems: [
          "Lower cost and higher margin",
          "Lower risk and a higher quality",
          "Market certainty",
          "Maximised time value of capital",
          "Safer work practices",
          "Less waste",
        ],
        lowerContent: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The problems with traditional construction are inherent in the process: buildings are designed for site works, delivered by contractors and dependent on trades. Slow delivery and quality issues from a low-tech approach compound construction risks which include high holding costs, mounting interest payments, low margins and property market shifts.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"PT Blink’s Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" process and technology platform takes the risk out of construction by applying manufacturing principles and centralised control to produce entire buildings. The process allows integrated teams to standardise core building architectural and engineering systems, while still offering a wide range of configurable design elements.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"This approach offers you the speed and risk assurance advantages of a repeatable product, while retaining the freedom needed to deliver the creative intent and performance attributes of your architectural design.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"With PT Blink, you achieve compliance with building and energy codes, a complete bill of materials up front, and an efficient process that enables a faster path to early estimates, feasibility, permits, manufacturing, assembly, integration and delivery.","marks":[],"data":{}}]}]}',
        },
        id: "be1eac6d-fc4a-5067-b055-0e98fde33f82",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Design - Manufacture - Integrate",
        displayType: "white",
        numberOfColumns: 3,
        headingLogo: {
          file: {
            url: "//images.ctfassets.net/thdo0maiis3p/6ziQq181drzbTW2PlV9Vzu/e4c7cd1333e86b16a8c6f4478c9ea167/Blink-DMI.svg",
          },
        },
        summary: null,
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "658aef62-488f-5bab-9422-75f8a24b71c7",
            title: {
              internal: {
                content: "Design",
              },
            },
            image: {
              title: "Design",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/4ZvocliOI7aUJOSzCeyusW/89c8340e991b08b2432f29fb4a3c51af/Design.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Computer design for manufacture enables speed, accuracy and compliance. Clashes are detected and rectified in the software.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/design/",
            ctaText: "Learn more",
          },
          {
            id: "67b647f0-0521-5324-b0d3-ced3aa70635a",
            title: {
              internal: {
                content: "Manufacture",
              },
            },
            image: {
              title: "Manufacture",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/2XA6cJgh5nArzbW2gmXttD/aa714eee82c94ec3f98d2a5efd9a6565/Manufacture.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Concurrent manufacture of the structure and components slashes project time while reducing waste and injury.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/manufacture/",
            ctaText: "Learn more",
          },
          {
            id: "db6a2500-2ff6-559d-b517-61bc3c906e8e",
            title: {
              internal: {
                content: "Integrate",
              },
            },
            image: {
              title: "Integrate",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/3nlIYf1FfezR0vWf7E5F32/a63aca64e3657426d30eb29d2f00d067/Integrate.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Removing the structure from the critical path, integration takes place earlier and much faster as services are built into components.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/integrate/",
            ctaText: "Learn more",
          },
        ],
        id: "23471d95-7d55-5e99-a1ec-e16858c55d8d",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Get Started",
        displayType: "blue",
        numberOfColumns: 2,
        headingLogo: null,
        summary: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Let’s talk! Contact us with general enquiries or register your specific interest below.","marks":[],"data":{}}],"data":{}}]}',
        },
        buttonText: "Contact Us",
        buttonLink: "/contact/",
        cards: [
          {
            id: "99314b58-f0a4-5082-a598-4a304eb53733",
            title: {
              internal: {
                content: "FREE consultation -\nProperty Owners & Developers",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Receive a free initial project assessment and understand the likely benefits of using Blink DMI®.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/book-consultation/",
            ctaText: "Book free consult",
          },
          {
            id: "13f6c73d-182e-58b1-83ad-3090fb55944b",
            title: {
              internal: {
                content: "Designers, Manufacturers and Integrators (builders)",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Join to access projects and move up the value chain.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/register/",
            ctaText: "Register to join",
          },
        ],
        id: "e2b5a91c-da91-57d0-b2ac-0967dc096306",
      },
    ],
  },
};

function DmiProcess() {
  return <Page data={data} />;
}

export default DmiProcess;
